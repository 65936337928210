import { AxiosResponse } from "axios"
import { AuthClient } from "ftl-core"

//types
import {
  createUtilsRepository,
  createRepositoryInstance,
  createProfileRepository,
  createCountryRepository,
  createEntityRepository,
  ListResponse,
  BasicResponse,
  Dictionary,
} from "ftl-core"
import {
  Brand,
  BrandRequest,
  City,
  CityRequest,
  Courier,
  CourierRequest,
  ExternalService,
  ExternalServiceAccount,
  ExternalServiceAccountRequest,
  ExternalServiceProperty,
  ExternalServicePropertyRequest,
  ExternalServiceRequest,
  Order,
  OrderEvent,
  OrderEventRequest,
  OrderRequest,
  OrderSyncServiceAccount,
  Organization,
  OrganizationRequest,
  Restaurant,
  RestaurantRequest,
  Role,
  RoleRequest,
  ServiceZone,
  ServiceZoneRequest,
  SMS,
  SMSRequest,
  TimeZone,
  TimeZoneRequest,
} from "../types/entities"

enum Versions {
  v1 = "v1",
  v2 = "v2",
}

// export const baseUrl =
//   process.env.REACT_APP_ENV === "dev"
//     ? `https://ftl-tech-admin-api-stg1.sushivesla.net/api/${Versions.v1}`
//     : `https://consumers.tech-admin.fdelivery.first.foodtech-lab.ru/api/${Versions.v1}`
export const baseUrl =
  process.env.REACT_APP_ENV === "dev"
    ? process.env.DEV_URL
      ? process.env.DEV_URL
      : `https://consumers.tech-admin.fdelivery.first.ftl-dev.ru/api/${Versions.v1}`
    : process.env.PROD_URL
    ? process.env.PROD_URL
    : `https://consumers.tech-admin.fdelivery.first.foodtech-lab.ru/api/${Versions.v1}`

// export const apiBaseUrl =
//   process.env.REACT_APP_ENV === "dev"
//     ? "https://ftl-tech-admin-api-stg1.sushivesla.net"
//     : "https://consumers.tech-admin.fdelivery.first.foodtech-lab.ru"
export const apiBaseUrl =
  process.env.REACT_APP_ENV === "dev"
    ? process.env.API_DEV_URL
      ? process.env.API_DEV_URL
      : "https://consumers.tech-admin.fdelivery.first.ftl-dev.ru"
    : process.env.API_PROD_URL
    ? process.env.API_PROD_URL
    : "https://consumers.tech-admin.fdelivery.first.foodtech-lab.ru"

export const repository = createRepositoryInstance({
  baseUrl,
  tokenRefresh: () => AuthAPI.refresh(),
})

repository.defaults.timeout =
  process.env.DEFAULT_CONNECTION_TIMEOUT !== undefined
    ? Number(process.env.DEFAULT_CONNECTION_TIMEOUT)
    : 30000
export const AuthAPI = new AuthClient(
  repository,
  {
    signIn: `${apiBaseUrl}/not-secure/api/v1/auth/login/email`,
    signOut: `${apiBaseUrl}/not-secure/api/v1/auth/logout`,
    refresh: `${apiBaseUrl}/not-secure/api/v1/auth/refresh`,
  },
  {
    loginPropName: "email",
  }
)

export const UtilsAPI = createUtilsRepository(repository)

//Profile

export const ProfileAPI = createProfileRepository(repository)

//Countries

export const CountryAPI = createCountryRepository(repository, UtilsAPI)

//Picture

export const PictureAPI = Object.freeze({
  post: (request: FormData): Promise<AxiosResponse<{ result: string }>> => {
    return repository.post(`pictures`, request)
  },
})

//Couriers
const normalizeCourierPayload = (payload: Courier): Courier => {
  let externalSystems: Courier["externalSystems"] = []
  payload.externalSystems.forEach((item) => {
    if (item)
      externalSystems.push({
        externalSystemSyncAccountId: item.externalSystemSyncAccountId,
        id: item.id,
      })
  })
  return {
    cityId: payload.cityId,
    countryId: payload.countryId,
    serviceZoneId: payload.serviceZoneId,
    organizationId: payload.organizationId,
    secondName: payload.secondName,
    firstName: payload.firstName,
    lastName: payload.lastName,
    phoneNumber: payload.phoneNumber,
    profileImageId: payload.profileImageId,
    externalSystems: externalSystems,
    accountStatus: payload.accountStatus,
  }
}

export const CourierAPI = Object.freeze({
  ...createEntityRepository<
    CourierRequest,
    Courier<"GET">,
    Courier,
    Courier<"PATCH">
  >({
    repositoryInstance: repository,
    utils: UtilsAPI,
    normalizeEntityPayload: normalizeCourierPayload,
    basePath: "couriers",
  }),
  switchAccountStatus: (id: string) => {
    return repository.post(`couriers/${id}/account-status`)
  },
  getByServiceId: (params: CourierRequest) =>
    UtilsAPI.getWithParams(`externalSystem/iiko/courier`, params),
  deleteAvatar: (id: string) =>
    repository.delete(`couriers/${id}/profile-image`),
  getIIKOCourierById: (
    id: string,
    params?: CourierRequest
  ): Promise<
    AxiosResponse<{
      result: {
        fullName?: string
        id: string
        externalSystemSyncAccountId: string
      }
    }>
  > => UtilsAPI.getWithParams(`externalSystem/iiko/courier/${id}`, params),
})

//Organizations

const normalizeOrganizationPayload = (payload: Organization): Organization => ({
  activeStatus: payload.activeStatus,
  name: payload.name,
})

export const OrganizationAPI = createEntityRepository<
  OrganizationRequest,
  Organization<"GET">,
  Organization,
  Organization<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeOrganizationPayload,
  basePath: "organizations",
  utils: UtilsAPI,
})

//Cities

const normalizeCityPayload = (payload: City): City => ({
  activeStatus: payload.activeStatus,
  countryId: payload.countryId,
  name: payload.name,
  timeZoneId: payload.timeZoneId,
  type: payload.type,
})

export const CityAPI = createEntityRepository<
  CityRequest,
  City<"GET">,
  City,
  City<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeCityPayload,
  basePath: "cities",
  utils: UtilsAPI,
})

// ServiceZones

const normalizeServiceZonePayload = (payload: ServiceZone): ServiceZone => ({
  activeStatus: payload.activeStatus,
  countryId: payload.countryId,
  cityId: payload.cityId,
  organizationId: payload.organizationId,
  dispatcherPhone: payload.dispatcherPhone,
  restaurantIds: payload.restaurantIds,
  brandIds: payload.brandIds,
  name: payload.name,
})

export const ServiceZoneAPI = createEntityRepository<
  ServiceZoneRequest,
  ServiceZone<"GET">,
  ServiceZone,
  ServiceZone<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeServiceZonePayload,
  basePath: "service-zones",
  utils: UtilsAPI,
})

//Time zones

const normalizeTimeZonePayload = (payload: TimeZone): TimeZone => ({
  activeStatus: payload.activeStatus,
  isDefault: payload.isDefault,
  name: payload.name,
  zoneId: payload.zoneId,
})

export const TimeZoneAPI = createEntityRepository<
  TimeZoneRequest,
  TimeZone<"GET">,
  TimeZone,
  TimeZone<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeTimeZonePayload,
  basePath: "time-zones",
  utils: UtilsAPI,
})

//Orders

const normalizeOrderRequest = (request: OrderRequest): OrderRequest => ({
  ...request,
  paymentTypes: request.paymentTypes?.toString(),
  sources: request.sources?.toString(),
  statuses: request.statuses?.toString(),
})

export const OrderAPI = Object.freeze({
  getList: (
    params: OrderRequest = {}
  ): Promise<AxiosResponse<ListResponse<Order>>> => {
    return UtilsAPI.getWithParams(`order`, normalizeOrderRequest(params))
  },
  getEvents: (
    id: string,
    params: OrderEventRequest = {}
  ): Promise<AxiosResponse<ListResponse<OrderEvent>>> => {
    return UtilsAPI.getWithParams(`order/${id}/events`, params)
  },
  getById: (id: string): Promise<AxiosResponse<BasicResponse<Order>>> => {
    return repository.get(`order/${id}`)
  },
  getSourceData: (
    id: string
  ): Promise<AxiosResponse<BasicResponse<Dictionary<string>>>> => {
    return repository.get(`order/${id}/external-systems/source-data`)
  },
})

//Brands

const normalizeBrandPayload = (payload: Brand): Brand => ({
  name: payload.name,
  activeStatus: payload.activeStatus,
  logoId: payload.logoId,
  organizationId: payload.organizationId,
})

export const BrandAPI = Object.freeze({
  ...createEntityRepository<BrandRequest, Brand<"GET">, Brand, Brand<"PATCH">>({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeBrandPayload,
    basePath: "brands",
    utils: UtilsAPI,
  }),
  deletePicture: (id: string): Promise<AxiosResponse> => {
    return repository.delete(`brands/${id}/logo`)
  },
})

//ExternalServices

const normalizeExternalServicePayload = (
  payload: ExternalService
): ExternalService => ({
  activeStatus: payload.activeStatus,
  baseApiUrl: payload.baseApiUrl,
  microserviceInteractionSettings: payload.microserviceInteractionSettings,
  name: payload.name,
  type: payload.type,
})

export const ExternalServicesAPI = createEntityRepository<
  ExternalServiceRequest,
  ExternalService<"GET">,
  ExternalService,
  ExternalService<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeExternalServicePayload,
  basePath: "external-services",
  utils: UtilsAPI,
})

//ExternalServicesProperties

const normalizeExternalServicePropertyPayload = (
  payload: ExternalServiceProperty
): ExternalServiceProperty => ({
  activeStatus: payload.activeStatus,
  externalServiceId: payload.externalServiceId,
  isRequired: payload.isRequired,
  localized: payload.localized,
  localizedHelper: payload.localizedHelper,
  name: payload.name,
  type: payload.type,
})

export const ExternalServicePropertiesAPI = Object.freeze({
  getList: (
    params: ExternalServicePropertyRequest
  ): Promise<AxiosResponse<ListResponse<ExternalServiceProperty<"GET">>>> => {
    return UtilsAPI.getWithParams(
      `external-services/${params.id}/properties`,
      params
    )
  },

  create: (
    payload: ExternalServiceProperty
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(
      `external-services/${payload.externalServiceId}/properties`,
      normalizeExternalServicePropertyPayload(payload)
    )
  },
  edit: (
    payload: ExternalServiceProperty<"PATCH">
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.patch(
      `external-services/${payload.externalServiceId}/properties/${payload.id}`,
      normalizeExternalServicePropertyPayload(payload)
    )
  },
  getById: (
    externalServiceId: string,
    propertyId?: string
  ): Promise<AxiosResponse<BasicResponse<ExternalServiceProperty<"GET">>>> => {
    return repository.get(
      `external-services/${externalServiceId}/properties/${propertyId}`
    )
  },

  switchStatus: (
    entityName: string,
    id: string,
    propertyId: string
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(
      `${entityName}/${id}/properties/${propertyId}/active-status`
    )
  },
})

//ExternalServiceAccounts

const normalizeExternalServiceAccountPayload = (
  payload: ExternalServiceAccount
): ExternalServiceAccount => ({
  activeStatus: payload.activeStatus,
  brandId: payload.brandId,
  externalServiceId: payload.externalServiceId,
  isDefault: payload.isDefault,
  name: payload.name,
  organizationId: payload.organizationId,
  properties: payload.properties,
  type: payload.type,
})

export const ExternalServiceAccountsAPI = Object.freeze({
  getList: (
    params: ExternalServiceAccountRequest
  ): Promise<AxiosResponse<ListResponse<ExternalServiceAccount<"GET">>>> => {
    return UtilsAPI.getWithParams(
      `external-services/${params.id}/accounts`,
      params
    )
  },

  create: (
    payload: ExternalServiceAccount
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(
      `external-services/${payload.externalServiceId}/accounts`,
      normalizeExternalServiceAccountPayload(payload)
    )
  },
  edit: (
    payload: ExternalServiceAccount<"PATCH">
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.patch(
      `external-services/${payload.externalServiceId}/accounts/${payload.id}`,
      normalizeExternalServiceAccountPayload(payload)
    )
  },
  getById: (
    externalServiceId: string,
    propertyId?: string
  ): Promise<AxiosResponse<BasicResponse<ExternalServiceAccount<"GET">>>> => {
    return repository.get(
      `external-services/${externalServiceId}/accounts/${propertyId}`
    )
  },
  switchStatus: (
    entityName: string,
    id: string,
    propertyId: string
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(
      `${entityName}/${id}/accounts/${propertyId}/active-status`
    )
  },
})

//Source

export const SourceAPI = Object.freeze({
  getAll: (): Promise<AxiosResponse<ListResponse<string[]>>> => {
    return repository.get(`sources/all`)
  },
})

//SMS

export const SmsAPI = Object.freeze({
  ...createEntityRepository<SMSRequest, SMS<"GET">, SMS, SMS<"PATCH">>({
    repositoryInstance: repository,
    basePath: "sms",
    utils: UtilsAPI,
  }),
  deleteById: (id: string): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(`sms/${id}/delete`)
  },
  deleteByPhone: (
    phoneNumber: string
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(`sms/delete-by-phone`, { phone: phoneNumber })
  },
  getAccessCode: (): Promise<AxiosResponse> => {
    return repository.get(`sms-settings/debug-mode/access-code`)
  },
  getWithoutCodes: (): Promise<AxiosResponse> => {
    return repository.get(`sms-settings/debug-mode/phones`)
  },
  putWithoutCodes: (request: string[]): Promise<AxiosResponse> => {
    return repository.put(`sms-settings/debug-mode/phones`, request)
  },
})

//Order sync service accounts

export const OrderSyncServiceAccountAPI = Object.freeze({
  getAll: (): Promise<AxiosResponse<ListResponse<OrderSyncServiceAccount>>> => {
    return repository.get(`order-sync-service-accounts/all`)
  },
})

//Roles

export const RoleAPI = createEntityRepository<RoleRequest, Role>({
  repositoryInstance: repository,
  basePath: "roles",
  utils: UtilsAPI,
})

//Restaurants
const normalizeRestaurantPayload = (payload: Restaurant): Restaurant => ({
  name: payload.name,
  id: payload.id,
  createdAt: payload.createdAt,
  brandId: payload.brandId,
  countryId: payload.countryId,
  address: payload.address,
  cityId: payload.cityId,
  dispatcherPhone: payload.dispatcherPhone,
  syncDate: payload.syncDate,
  externalSystems: payload.externalSystems,
})

export const RestaurantAPI = createEntityRepository<
  RestaurantRequest,
  Restaurant
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeRestaurantPayload,
  basePath: "restaurants",
  utils: UtilsAPI,
})
