import {
  AreaIcon,
  BrandIcon,
  BubbleIcon,
  CityIcon,
  CountryIcon,
  CourierIcon,
  FTLLogoIcon,
  OrderIcon,
  OrganizationIcon,
  theme,
  TimeIcon,
} from "ftl-uikit"

import { Routes } from "./Routes"

import { createStore } from "redux"
import { UIMain } from "ftl-dashboards-templates"
import { AuthAPI, ProfileAPI } from "./repository"
import {
  basicReducer,
  initialState,
  logOutAction,
  setProfileAction,
  handleError,
} from "ftl-core"

const store = createStore(
  basicReducer,
  initialState,
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const App = () => {
  // const sayswho = function () {
  //   let ua = navigator.userAgent,
  //     tem,
  //     M =
  //       ua.match(
  //         /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
  //       ) || []
  //   if (/trident/i.test(M[1])) {
  //     tem = /\brv[ :]+(\d+)/g.exec(ua) || []
  //     return "IE " + (tem[1] || "")
  //   }
  //   if (M[1] === "Chrome") {
  //     tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
  //     if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera")
  //   }
  //   M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"]
  //   if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  //   return M.join(" ")
  // }

  // if (sayswho() === "Chrome 94") {
  //   try {
  //     throw Error
  //   } catch (error) {
  //     handleError({
  //       error,
  //       defaultError:
  //         "В вашей версии браузера некоторые функции могут работать некорректно. Обновите, пожалуйста, Ваш браузер до актуальной версии",
  //     })
  //   }
  // }
  return (
    <UIMain
      redirectOnAuthUrl="/couriers"
      project={{
        title: {
          authPage: "Войти в FDelivery Admin",
          header:
            process.env.REACT_APP_ENV === "dev"
              ? "FDelivery Admin - DEV"
              : "FDelivery Admin",
        },
        logo: {
          authPage: <FTLLogoIcon baseColor="#DE2222" />,
          header: <FTLLogoIcon baseColor="#DE2222" sizeRatio={0.6} />,
        },
      }}
      AuthClient={AuthAPI}
      StoreProviderProps={{
        store,
        getProfile: ProfileAPI.get,
      }}
      theme={theme}
      BaseLayoutProps={{
        onMenuClick: async () => {
          if (!store.getState().globalStore.profile.id)
            try {
              const profile = (await ProfileAPI.get()).data.result
              store.dispatch(setProfileAction(profile))
            } catch (error) {
              handleError({
                error,
                defaultError: "Произошла ошибка при получении данных профиля",
              })
            }
        },

        onSignOut: () => store.dispatch(logOutAction),
        sideMenuItems: [
          {
            icon: <CountryIcon />,
            label: "Страны",
            to: "/countries/",
          },
          {
            icon: <CityIcon />,
            label: "Города",
            to: "/cities",
          },
          {
            icon: <AreaIcon />,
            label: "Зоны обслуживания",
            to: "/service-zones",
          },
          {
            icon: <TimeIcon />,
            label: "Часовые пояса",
            to: "/time-zones",
          },
          {
            icon: <OrganizationIcon />,
            label: "Организации",
            to: "/organizations",
          },
          {
            icon: <BrandIcon />,
            label: "Бренды",
            to: "/brands",
          },
          {
            icon: <CourierIcon />,
            label: "Курьеры",
            to: "/couriers",
          },
          {
            icon: <BubbleIcon />,
            label: "Коды авторизации",
            to: "/codes",
            items: [
              {
                label: "Отправленные",
                to: "/codes/sended",
              },
              {
                label: "Вход без SMS",
                to: "/codes/unsend",
              },
            ],
          },
          {
            icon: <OrderIcon />,
            label: "Заказы",
            to: "/orders",
          },
        ],
      }}
    >
      <Routes />
    </UIMain>
  )
}

export default App
